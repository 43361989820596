var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"dis-flex a-i-center j-c-center h-527",style:(_vm.newModel1)},[_c('h3',{staticClass:"c-fefefe fs-48 fw-b"},[_vm._v(_vm._s(_vm.$g("newBG.title")))])]),_c('ul',{staticClass:"mlr-auto w-1040"},_vm._l((_vm.articleList || []),function(item,index){return _c('li',{key:index,staticClass:"mt-70 dis-flex"},[_c('img',{attrs:{"width":"300","height":"170","src":item.image1,"alt":""}}),_c('div',{staticClass:"h-170 lh-18 ml-40 pb-10 flex-1 dis-flex flex-column j-c-between new-model2-listright"},[_c('h3',{staticClass:"fs-16 c-3 fw-b"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"fs-14 c-6 mt-20 text-td-3"},[_vm._v(_vm._s(item.memo))]),_c('div',{staticClass:"dis-flex j-c-between a-i-center mt-20"},[_c('span',{staticClass:"fs-12 c-9"},[_vm._v(_vm._s(item.ext1))]),_c('span',{staticClass:"dis-ib c-b41122 fs-12 pb-4 cp bb-main",on:{"click":function($event){return _vm.toDetail(item)}}},[_vm._v(_vm._s(_vm.$g("newOther.title")))])])])])}),0),_c('ul',{staticClass:"mlr-auto w-1040 dis-flex a-i-center j-c-r ptb-45 fs-12"},[_c('li',{class:[
        'bg-main c-f plr-15 h-20 lh-20 cp mr-15',
        _vm.page == 1 ? 'disable' : '' ],on:{"click":_vm.prevClick}},[_vm._v(" "+_vm._s(_vm.$g("newOther.ext1"))+" ")]),_vm._l((_vm.pageTotal - 1),function(item,index){return [(item < _vm.size || _vm.page == item || _vm.page > item)?_c('li',{key:index,class:[
          'w-20 cp h-20 dis-flex a-i-center j-c-center ml-5',
          _vm.page == item ? 'c-main bd-main' : 'bd-b1b1b1 c-b1b1b1' ],on:{"click":function($event){return _vm.pageClick(item)}}},[_c('span',[_vm._v(_vm._s(item))])]):_vm._e()]}),(_vm.pageTotal > 5 && _vm.page != _vm.pageTotal - 1 && _vm.page != _vm.pageTotal)?_c('li',{staticClass:"w-20 h-20 dis-flex a-i-center j-c-center ml-5 bd-b1b1b1 c-b1b1b1"},[_vm._v(" ... ")]):_vm._e(),_c('li',{class:[
        'w-20 h-20 cp dis-flex a-i-center j-c-center ml-5',
        _vm.page == _vm.pageTotal ? 'c-main bd-main' : 'bd-b1b1b1 c-b1b1b1' ],on:{"click":function($event){return _vm.pageClick(_vm.pageTotal)}}},[_vm._v(" "+_vm._s(_vm.pageTotal)+" ")]),_c('li',{class:[
        'bg-main c-f plr-15 h-20 lh-20 cp ml-30',
        _vm.page == _vm.pageTotal ? 'disable' : '' ],on:{"click":_vm.nextClick}},[_vm._v(" "+_vm._s(_vm.$g("newOther.ext2"))+" ")])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }
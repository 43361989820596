<template>
  <div id="idBusinessDetail">
    <template v-for="(item, index) in $t.businessListDetailUp">
      <div v-if="navIndex == index" :key="index" class="por h-527">
        <img class="object-c" width="100%" height="100%" :src="item.image1" alt="" />
        <h3
          class="fs-48 c-f fw-b dis-flex a-i-center j-c-center business-detail-title"
        >
          {{ $g('businessListDetailTitle.title') }}
        </h3>
        <ul
          class="dis-flex mlr-120 a-i-center pd-20 fs-12 c-878787 business-detail-nav"
        >
          <li
            :class="[
              'h-p100 flex-1 plr-20 txt-c cp bg-f dis-flex a-i-center j-c-center',
              index == 0 ? '' : 'ml-4',
              navIndex == index ? 'c-f bg-main active' : '',
            ]"
            v-for="(item, index) in $t.businessListDetailUp"
            :key="index"
            @click="navClick(index)"
          >
            {{ item.title }}
          </li>
        </ul>
      </div>
    </template>
    <!-- index==0 -->
    <ul v-if="navIndex == 0">
      <li class="bg-f dis-flex a-i-center por business-detail-model1">
        <div class="mlr-auto w-1040">
          <div class="dis-flex ov-h business-detail-model1-left">
            <img width="527" height="527" :src="$t.businessListDetailUp ? $t.businessListDetailUp[0].ext1 : ''" alt="" />
          </div>
          <div class="dis-flex ov-h business-detail-model1-top">
            <img width="457" height="457" :src="$t.businessListDetailUp ? $t.businessListDetailUp[0].ext2 : ''" alt="" />
          </div>
          <div class="dis-flex ov-h business-detail-model1-bottom">
            <img width="457" height="457" :src="$t.businessListDetailUp ? $t.businessListDetailUp[0].ext3 : ''" alt="" />
          </div>
          <h3
            class="h-135 dis-flex txt-c a-i-center j-c-center por fs-18 c-0 fw-b business-detail-model1-title"
          >
            {{ $t.businessListDetailUp ? $t.businessListDetailUp[0].title : '' }}
          </h3>
          <div class="c-3 lh-20 mt-60 ck-content" v-html="$t.businessListDetailUp ? $t.businessListDetailUp[0].bigtext : ''"></div>
        </div>
      </li>
      <li class="ptb-60 business-detail1-model2">
        <div class="w-900 mlr-auto">
          <h3 class="fs-18 c-f fw-b">{{ $g('businessListDetailDown1Title.title') }}</h3>
          <ul class="dis-flex flex-wrap mt-70">
            <li
              class="dis-flex flex-column txt-c pd-15 a-i-center j-c-center w-160 br-10 bg-f7f9fa ml-10 mr-10 mb-15"
              v-for="(item, index) in $t.businessListDetailDown1List"
              :key="index"
            >
              <img :src="item.image1" alt="" />
              <h4 class="fs-12 c-3 mt-15">{{ item.title }}</h4>
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <!-- index==1 -->
    <ul v-if="navIndex == 1">
      <li class="bg-f dis-flex a-i-center por business-detail-model1">
        <div class="mlr-auto w-1040">
          <div class="dis-flex ov-h business-detail-model1-left">
            <img width="527" height="527" :src="$t.businessListDetailUp ? $t.businessListDetailUp[1].ext1 : ''" alt="" />
          </div>
          <div
            class="dis-flex ov-h business-detail-model1-top business-detai2-model1-top"
          >
            <img width="457" height="457" :src="$t.businessListDetailUp ? $t.businessListDetailUp[1].ext2 : ''" alt="" />
          </div>
          <div class="dis-flex ov-h business-detail-model1-bottom">
            <img width="457" height="457" :src="$t.businessListDetailUp ? $t.businessListDetailUp[1].ext3 : ''" alt="" />
          </div>
          <h3
            class="h-135 dis-flex txt-c a-i-center j-c-center por fs-18 c-0 fw-b business-detail-model1-title"
          >
            {{ $t.businessListDetailUp ? $t.businessListDetailUp[1].title : '' }}
          </h3>
          <div class="c-3 lh-20 mt-60 ck-content" v-html="$t.businessListDetailUp ? $t.businessListDetailUp[1].bigtext : ''"></div>
        </div>
      </li>
    </ul>
    <!-- index==2 -->
    <ul v-if="navIndex == 2">
      <li class="bg-f dis-flex a-i-center por business-detail-model1">
        <div class="mlr-auto w-1040">
          <div class="dis-flex ov-h business-detail-model1-left">
            <img width="527" height="527" :src="$t.businessListDetailUp ? $t.businessListDetailUp[2].ext1 : ''" alt="" />
          </div>
          <div
            class="dis-flex ov-h business-detail-model1-top business-detai2-model1-top"
          >
            <img width="457" height="457" :src="$t.businessListDetailUp ? $t.businessListDetailUp[2].ext2 : ''" alt="" />
          </div>
          <div class="dis-flex ov-h business-detail-model1-bottom">
            <img width="457" height="457" :src="$t.businessListDetailUp ? $t.businessListDetailUp[2].ext3 : ''" alt="" />
          </div>
          <h3
            class="h-135 dis-flex txt-c a-i-center j-c-center por fs-18 c-0 fw-b business-detail-model1-title"
          >
            {{ $t.businessListDetailUp ? $t.businessListDetailUp[2].title : '' }}
          </h3>
          <div class="c-3 lh-20 mt-60 ck-content" v-html="$t.businessListDetailUp ? $t.businessListDetailUp[2].bigtext : ''"></div>
        </div>
      </li>
      <li
        class="h-500 mlr-auto w-1200 pl-150 dis-flex a-i-center business-detail3-model2"
      >
        <span class="fs-18 c-f bg-main fw-b h-65 dis-ib plr-20 lh-65">{{
          $g('businessListDetailDown31.title')
        }}</span>
      </li>
      <li class="dis-flex mlr-auto w-1200">
        <div
          class="flex-1 bg-main c-f lh-24 pl-150 dis-flex flex-column j-c-center pb-20 pr-20"
          v-html="$t.businessListDetailDown32.content"
        ></div>
        <img width="638" height="402" :src="$t.businessListDetailDown32.image1" alt="" />
      </li>
      <li
        class="h-500 mlr-auto w-1200 dis-flex a-i-center j-c-center business-detail3-model4"
      >
        <span class="fs-18 c-f bg-main fw-b h-65 dis-ib plr-20 lh-65">{{
          $g('businessListDetailDown33.title')
        }}</span>
      </li>
      <li class="dis-flex mlr-auto w-1200">
        <img width="649" height="421" :src="$g('businessListDetailDown34.image1')" alt="" />
        <div
          class="flex-1 bg-main c-f lh-24 pl-100 dis-flex flex-column j-c-center pb-20 pr-20"
          v-html="$g('businessListDetailDown34.content')"
        ></div>
      </li>
    </ul>
    <!-- index==3 -->
    <ul v-if="navIndex == 3">
      <li class="bg-f por dis-flex a-i-center business-detail-model1">
        <div class="mlr-auto w-1040">
          <div class="dis-flex ov-h business-detail-model1-left">
            <img width="527" height="527" :src="$t.businessListDetailUp ? $t.businessListDetailUp[3].ext1 : ''" alt="" />
          </div>
          <div
            class="dis-flex ov-h business-detail-model1-top business-detai2-model1-top"
          >
            <img width="457" height="457" :src="$t.businessListDetailUp ? $t.businessListDetailUp[3].ext2 : ''" alt="" />
          </div>
          <div class="dis-flex ov-h business-detail-model1-bottom">
            <img width="457" height="457" :src="$t.businessListDetailUp ? $t.businessListDetailUp[3].ext3 : ''" alt="" />
          </div>
          <h3
            class="h-135 dis-flex txt-c a-i-center j-c-center por fs-18 c-0 fw-b business-detail-model1-title"
          >
            {{ $t.businessListDetailUp ? $t.businessListDetailUp[3].title||"" : '' }}
          </h3>
          <div class="c-3 lh-20 mt-60 ck-content" v-html="$t.businessListDetailUp ? $t.businessListDetailUp[3].bigtext : ''"></div>
        </div>
      </li>
      <li
        class="fs-18 c-f fw-b dis-flex a-i-center plr-140 business-detail4-model2"
      >
        <div class="mlr-auto w-1200">{{ $g('businessListDetailDown41.title') }}</div>
      </li>
      <li class="pt-65 txt-c pb-50">
        <ul class="mlr-auto w-1040 dis-flex por txt-c detail4-model3">
          <li
            :class="['flex-1 por', index == 1 ? 'mlr-180 index2' : '']"
            v-for="(item, index) in $t.businessListDetailDown42"
            :key="index"
          >
            <h3
              :class="[
                index == 0 ? 'c-1f54f4' : index == 1 ? 'c-fdc800' : 'c-0dcdbc',
              ]"
            >
              {{ item.title }}
            </h3>
            <div
              :class="[
                'mt-40 c-f por bra-half dis-flex flex-column j-c-center index0',
                index == 0
                  ? 'bg-1f54f4'
                  : index == 1
                  ? 'bg-fdc800'
                  : 'bg-0dcdbc',
              ]"
            >
              <div class="cir-txt">
                <p class="fs-20 fw-b">{{ item.ext5 }}</p>
                <p class="fs-14 mt-10">{{ item.content }}</p>
              </div>
            </div>
          </li>
          <li class="arrow">
            <img
              width="111px"
              height="145px"
              src="/static/images/business/detail4/model3-1.png"
              alt=""
            />
            <p class="fs-14 mt-10">{{$g('businessListDetailDown43.ext1')}}</p>
          </li>
        </ul>
        <div>
          <img src="/static/images/business/detail4/model3-2.png" alt="" />
        </div>
        <p class="fs-18 mb-15">{{ $g('businessListDetailDown43.title') }}</p>
        <ul class="dis-ib br-30 h-55 lh-55 bg-ad2d2d c-f fs-18">
          <li
            class="dis-ib plr-30"
            v-for="(item, index) in $t.businessListDetailDown44 || []"
            :key="index"
          >
            {{ item.title }}
          </li>
        </ul>
      </li>
    </ul>
    <!-- index==4 -->
    <ul v-if="navIndex == 4">
      <li class="bg-f por dis-flex a-i-center business-detail-model1">
        <div class="mlr-auto w-1040">
          <div class="dis-flex ov-h business-detail-model1-left">
            <img width="527" height="527" :src="$t.businessListDetailUp ? $t.businessListDetailUp[4].ext1 : ''" alt="" />
          </div>
          <div
            class="dis-flex ov-h business-detail-model1-top business-detai2-model1-top"
          >
            <img width="457" height="457" :src="$t.businessListDetailUp ? $t.businessListDetailUp[4].ext2 : ''" alt="" />
          </div>
          <div class="dis-flex ov-h business-detail-model1-bottom">
            <img width="227" height="250" :src="$t.businessListDetailUp ? $t.businessListDetailUp[4].ext3 : ''" alt="" />
          </div>
          <h3
            class="h-150 dis-flex plr-20 txt-c a-i-center j-c-center por fs-18 c-0 fw-b business-detail-model1-title"
          >
            {{ $t.businessListDetailUp ? $t.businessListDetailUp[4].title : '' }}
          </h3>
          <div class="c-3 lh-20 mt-60 ck-content" v-html="$t.businessListDetailUp ? $t.businessListDetailUp[4].bigtext : ''"></div>
        </div>
      </li>
      <li class="mb-30 business-detail-model2">
        <div>
          <img
            class="object-c"
            width="100%"
            height="321px"
            :src="$g('businessListDetailDown51.ext2')"
            alt=""
          />
        </div>
        <div class="dis-flex por mlr-auto w-1200 pt pr-30 j-c-between">
          <h3 class="fs-18 c-3 pt-120 fw-b">{{ $g('businessListDetailDown51.title') }}</h3>
          <img
            class="rightimg"
            width="754"
            height="707"
            :src="$g('businessListDetailDown51.ext4')"
            alt=""
          />
          <div
            class="c-f fw-b bg-main h-200 lh-24 pl-160 dis-flex a-i-center bottom"
            v-html="$g('businessListDetailDown51.content')"
          ></div>
        </div>
      </li>
      <li class="mlr-auto w-1200 bg-f6f6f6 dis-flex j-c-between">
        <div
          class="flex-1 dis-flex flex-column j-c-center pt-50 pr-45 pb-50 pl-140"
        >
          <h3 class="fs-18 c-3 fw-b">{{ $g('businessListDetailDown52.title') }}</h3>
          <p class="lh-18 mt-30">{{ $g('businessListDetailDown52.content') }}</p>
        </div>
        <img width="671" height="346" :src="$g('businessListDetailDown52.image1')" alt="" />
      </li>
      <li class="dis-flex">
        <div
          :class="[
            'flex-1 fs-16 h-110 dis-flex cp a-i-center j-c-center bg-e8e8e8 business-detail5-model4',
            index == 0 ? '' : ' ml-1',
          ]"
          v-for="(item, index) in $t.businessListDetailDown53"
          :key="index"
        >
          {{ item.title }}
        </div>
      </li>
    </ul>
    <!-- index==5 -->
    <ul v-if="navIndex == 5">
      <li class="bg-f por dis-flex a-i-center business-detail-model1">
        <div class="mlr-auto w-1040">
          <div class="dis-flex ov-h business-detail-model1-left">
            <img width="527" height="527" :src="$t.businessListDetailUp ? $t.businessListDetailUp[5].ext1 : ''" alt="" />
          </div>
          <div
            class="dis-flex ov-h business-detail-model1-top business-detai2-model1-top"
          >
            <img width="457" height="457" :src="$t.businessListDetailUp ? $t.businessListDetailUp[5].ext2 : ''" alt="" />
          </div>
          <div class="dis-flex ov-h business-detail-model1-bottom">
            <img width="457" height="457" :src="$t.businessListDetailUp ? $t.businessListDetailUp[5].ext3 : ''" alt="" />
          </div>
          <h3
            class="h-135 dis-flex txt-c a-i-center j-c-center por fs-18 c-0 fw-b business-detail-model1-title"
          >
            {{ $t.businessListDetailUp ? $t.businessListDetailUp[5].title : '' }}
          </h3>
          <div class="c-3 lh-20 mt-60 ck-content" v-html="$t.businessListDetailUp ? $t.businessListDetailUp[5].bigtext : ''"></div>
        </div>
      </li>
      <li class="c-f dis-flex flex-column j-c-center business-detail6-model2">
        <div class="w-1200 mlr-auto">
          <h3 class="fs-18 c-f fw-b">{{ $g('businessListDetailDown61.title') }}</h3>
          <p class="mt-20">{{ $g('businessListDetailDown61.content') }}</p>
        </div>
      </li>
      <li class="txt-c pt-70 w-1200 mlr-auto">
        <h3 class="fs-18 c-0 fw-b">{{ $g('businessListDetailDown62.title') }}</h3>
        <ul class="mt-40 dis-flex">
          <li
            class="flex-1 mlr-15 pd-40 br-10 bg-f0f4f6"
            v-for="(item, index) in $t.businessListDetailDown63 || []"
            :key="index"
          >
            <h4 class="fs-16">{{ item.title }}</h4>
            <p class="mt-12 mt-20">{{ item.content }}</p>
          </li>
        </ul>
        <p class="mt-40">
          <span class="dis-ib cp h-60 plr-60 lh-60 c-f bg-main fs-18 fw-b">{{
            $g('businessListDetailDown62.content')
          }}</span>
        </p>
      </li>
      <li class="mt-60 pb-60 dis-flex j-c-center business-detail6-model4">
        <ul class="br-10 txt-c dis-flex flex-wrap j-c-center list">
          <li
            class="w-200 pd-30"
            v-for="(item, index) in $t.businessListDetailDown64 || []"
            :key="index"
          >
            <div><img :src="item.image1" alt="" /></div>
            <p class="fs-16 mt-15">{{ item.title }}</p>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {
      navIndex: this.$route.query.index || 0,
    };
  },
  watch:{
      $route(n){
          this.navIndex = n.query.index || 0
      }
  },
  methods: {
    navClick(index) {
      this.navIndex = index;
      this.$router.replace({
        path: "/business/detail",
        query: { index: index },
      });
    },
  },
};
</script>
<style>
.business-detail-title {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.business-detail-nav {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 103px;
  background: rgba(255, 255, 255, 0.1);
}
.business-detail-nav .active {
  box-shadow: 0px 0px 13px 0px rgba(176, 33, 37, 0.57);
}
.business-detail-model1 {
  min-height: 605px;
  padding: 80px 195px 150px 344px;
}
.business-detail-model1-title {
  width: 331px;
  background: #f0f3f7;
}

.business-detail-model1-title::after {
  content: "";
  position: absolute;
  left: 16px;
  top: 12px;
  height: 100%;
  width: 100%;
  border: 2px solid #b02125;
}
.business-detail-model1-left {
  width: 264px;
  height: 527px;
  justify-content: flex-end;
  position: absolute;
  left: 0;
  top: 37px;
}
.business-detail-model1-top {
  width: 457px;
  height: 183px;
  align-items: flex-end;
  position: absolute;
  top: 36px;
  right: 0;
}
.business-detail-model1-bottom {
  width: 229px;
  height: 251px;
  object-fit: cover;
  position: absolute;
  bottom: 0;
  right: 0;
}
.business-detail1-model2 {
  background: url(/static/images/business/detail1/model2-bg.jpg) no-repeat
    center;
  background-size: cover;
}
.business-detai2-model1-top {
  height: 217px;
}
.business-detail3-model2 {
  background: url(/static/images/business/detail3/model2-bg.jpg) no-repeat
    center;
  background-size: cover;
}
.business-detail3-model4 {
  background: url(/static/images/business/detail3/model4-bg.jpg) no-repeat
    center;
  background-size: cover;
}
.business-detail4-model2 {
  height: 217px;
  background: url(/static/images/business/detail4/model2-bg.jpg) no-repeat
    center;
  background-size: cover;
}
.detail4-model3 .index0{
  height: 0;
  padding-top: 100%;
}
.index0 .cir-txt{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
.detail4-model3 .index0::before {
  content: "";
  position: absolute;
  top: -25px;
  right: -25px;
  left: -25px;
  bottom: -25px;
  background: inherit;
  opacity: 0.18;
  border-radius: 50%;
}
.detail4-model3 .index0::after {
  content: "";
  position: absolute;
  top: -15px;
  right: -15px;
  left: -15px;
  bottom: -15px;
  background: inherit;
  opacity: 0.4;
  border-radius: 50%;
  z-index: -1;
}
.detail4-model3 .index2::before {
  content: "";
  position: absolute;
  bottom: 40%;
  left: -161px;
  width: 136px;
  height: 3px;
  background: #c85555;
  opacity: 0.8;
}
.detail4-model3 .index2::after {
  content: "";
  position: absolute;
  bottom: 40%;
  right: -155px;
  width: 136px;
  height: 3px;
  background: #c85555;
  opacity: 0.8;
}
.detail4-model3 .arrow {
  position: absolute;
  left: -190px;
  bottom: -25px;
}
.business-detail-model2 .rightimg {
  margin-top: -230px;
}
.business-detail-model2 .bottom {
  position: absolute;
  bottom: 90px;
  left: 0;
  right: 370px;
  z-index: -1;
}
.business-detail5-model4:hover {
  color: #fff;
  background: #b02125;
}
.business-detail6-model2 {
  height: 262px;
  background: url(/static/images/business/detail6/model2-bg.jpg) no-repeat
    center;
  background-size: cover;
}
.business-detail6-model4 {
  background: url(/static/images/business/detail6/model4-bg.jpg) no-repeat
    center;
  background-size: cover;
  padding-top: 272px;
}
.business-detail6-model4 .list {
  width: 748px;
  padding: 30px 50px;
  background: rgba(255, 255, 255, 0.88);
}
</style>
<template>
    <div>
        <!-- 模块1 - 大图 -->
        <div class="dis-flex a-i-center j-c-center h-527 aboutus-model1">
            <h3 class="c-f fs-48 fw-b">{{$g('aboutsModel1.title')}}</h3>
        </div>
        <!-- 模块2 -  -->
        <div class="w-1200 mlr-auto dis-flex ptb-100">
            <div>
                <h3 class="c-3 fs-18">{{$g('aboutsModel21.title')}}</h3>
                <p><i class="dis-ib h-6 w-35 bg-main mt-10"></i></p>
                <p class="fs-12 c-9 lh-22 mt-30" v-html="$g('aboutsModel21.content')"></p>
                <ul class="mt-50 dis-flex">
                    <li class="mr-70" v-for="item,index in $t.aboutsModel22" :key="index">
                        <p class="fs-80 c-main fw-b">{{item.title}}</p>
                        <p class="c-3 fs-14 mt-15" v-html="item.content"></p>
                    </li>
                </ul>
            </div>
            <div class="pt-100">
                <img width="459" height="230" src="/static/images/aboutus/model2-bg.png" alt="">
            </div>
        </div>
        <!-- 模块3 -->
        <div class="dis-flex a-i-center aboutus-model3"> 
            <div class="w-1200 mlr-auto dis-flex">
                <div class="dis-flex a-i-center j-c-between">
                    <img width="500" height="500" :src="$g('aboutsModel31.image1')" alt="">
                </div>
                <div class="flex-1">
                    <div class="txt-r c-3">
                        <h3 class=" fs-18 fw-b">{{$g('aboutsModel31.title')}}</h3>
                        <p class="mt-10"><i class="dis-ib h-6 w-35 bg-main"></i></p>
                        <p class="fs-12 mt-15 lh-22" v-html="$g('aboutsModel31.content')"></p>
                    </div>
                    <ul class="mt-80 mb-200 por dis-flex j-c-around aboutus-model3-list">
                        <li v-for="(item,index) in $t.aboutsModel32 || []" :key="index" class="txt-c" :style="{opacity: JSON.stringify((index + 1 )* 0.1 + 0.5) }">
                            <p class="fs-22 mb-15 c-main fw-b">{{item.content}}</p>
                            <i class="dis-ib por bg-main around"></i>
                            <p class="c-3 mt-15">{{item.title}}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- 模块4 -->
        <div class="aboutus-model4">
            <div class="w-1200 mlr-auto">
                <h3 class="c-f fs-18 fw-b">{{$g('aboutsModel4.title')}}</h3>
                <p class="mt-5"><i class="dis-ib h-6 w-60 bg-main"></i></p>
            </div>
        </div>
        <!-- 模块5 -->
        <div class="pt-60 por w-1200 mlr-auto dis-flex aboutus-model5">
                <ul class="flex-1 mt-100">
                    <li class="c-0 dis-flex">
                        <div>
                            <h3 class="fs-30">{{$g('aboutsModel5.title')}}</h3>
                            <p class="mt-15">{{$g('aboutsModel5.content')}}</p>
                        </div>
                        <p class="ml-50 lh-30" v-html="$g('aboutsModel5.ext1')"></p>
                    </li>
                    <li class="fw-b aboutus-model5-title">{{$g('aboutsModel5.title')}}</li>
                    <li class="h-150 bg-main c-f dis-flex a-i-center pl-140 aboutus-model5-bottom">
                        <img width="106" height="105" :src="$g('aboutsModel5.image2')" alt="">
                        <p class="ml-60 fs-16 lh-30 w-220" v-html="$g('aboutsModel5.image2Tip')"></p>
                    </li>
                </ul>
                <div class="mr-180"><img width="461" height="542" :src="$g('aboutsModel5.image1')"></div>
        </div>
        <!-- 模块6 -->
        <div class="pt-60 w-1200 mlr-auto  dis-flex por aboutus-model6">
            <div class="ml-140"><img width="467" height="542" :src="$g('aboutsModel6.image1')"></div>
            <ul class="flex-1 mt-100">
                <li class="c-0 dis-flex a-i-center j-c-r">
                    <div>
                        <h3 class="fs-30">{{$g('aboutsModel6.title')}}</h3>
                        <p class="mt-15">{{$g('aboutsModel6.content')}}</p>
                    </div>
                    <p class="ml-50 lh-30" v-html="$g('aboutsModel6.ext1')"></p>
                    <p class="ml-30 lh-30" v-html="$g('aboutsModel6.ext2')"></p>
                </li>   
                <li class="fw-b aboutus-model6-title">{{$g('aboutsModel6.title')}}</li>
                <li class="h-150 bg-main c-f dis-flex a-i-center pr-50 j-c-r aboutus-model6-bottom">
                    <img width="106" height="105" :src="$g('aboutsModel6.image2')" alt="">
                    <p class="ml-60 fs-16 lh-30 w-200" v-html="$g('aboutsModel6.image2Tip')"></p>
                </li>
            </ul>
        </div>
        <!-- 模块7 -->
        <div class="mt-50 pt-80 aboutus-model7">
            <h3 class="fs-18 c-fefefe txt-c mb-25">
                <span class="por aboutus-model7-title">{{$g('aboutsModel71.title')}}</span>
            </h3>
            <p class="w-1040 mlr-auto fs-12 c-f txt-c pb-50">{{$g('aboutsModel71.content')}}</p>
            <div class="mlr-auto w-1040 txt-c por">
                <li class="lh-0"><img class="br-10 object-c" width="100%" height="478" :src="$g('aboutsModel71.image1')" alt=""></li>
                <li class="c-f dis-flex flex-column a-i-center j-c-center plr-70 aboutus-model7-list">
                    <h3>{{$g('aboutsModel72.title')}}</h3>
                    <ul class="dis-flex flex-wrap j-c-between mt-20">
                        <li class="mt-10 w-100" v-for="item,index in $t.aboutsModel73 || []" :key="index">{{item.title}}</li>
                    </ul>
                </li>
            </div>
        </div>
        <!-- 模块8 -->
        <div class="pt-140 mlr-auto w-1040">
            <h3 class="c-3 fs-18 fw-b">{{$g('aboutsModel81.title')}}</h3>
            <p class="mt-5"><i class="dis-ib h-6 w-30 bg-main"></i></p>
            <p class="fs-16 c-3 mt-25 mb-50">{{$g('aboutsModel81.content')}}</p>
            <ul class="dis-flex j-c-around ">
                <li :class="['bg-edf1f5 txt-c br-10 w-220',index==0?'':'ml-20']" v-for="item,index in $t.aboutsModel82 || []" :key="index">
                    <div class="bg-main h-135 br-tr-10 dis-flex a-i-center j-c-center"><img :src="item.image1" alt=""></div>
                    <h3 class="c-3 fs-18 fw-b plr-25 mt-15">{{item.title}}</h3>
                    <p class="fs-16 c-9 mt-5 pb-15 pl-25 pr-25">{{item.content}}</p>
                </li>
            </ul>
        </div>
        <!-- 模块9 -->
        <div class="mt-90 pt-60 pl-140 pr-150 pb-50 bg-f7f7f7" id="friends">
            <div class="w-1040 mlr-auto">
                <h3 class="c-3 fs-18 fw-b">{{$g('aboutsModel91.title')}}</h3>
                <p class="mt-10 lh-0"><i class="dis-ib h-6 w-30 bg-main"></i></p>
                <div class="dis-flex mt-35">
                    <div class="w-170">
                        <ul class="bg-main c-f txt-c dis-flex flex-column pd-25" >
                            <li :class="[index==0?'':'mt-40']" v-for="item,index in $t.aboutsModel92 || []" :key="index">
                                <p class="fs-30 fw-b">{{item.content}}</p>
                                <p class="fs-16 mt-5">{{item.title}}</p>
                            </li>
                        </ul>
                    </div>
                    <ul class="flex-1 dis-flex flex-wrap ml-60">
                        <li class="ml-15 mb-15 w-110 h-110 dis-flex j-c-center a-i-center bg-f" v-for="item,index in $t.aboutsModel93 || []" :key="index"><img :src="item.image1" alt=""></li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- 模块10 -->
        <div class="plr-140 aboutus-model10">
            <div class="w-1040 mlr-auto">
                <h3 class="c-3 fs-18 fw-b txt-r pt-65">{{$g('aboutsModel101.title')}}</h3>
                <p class="mt-10 txt-r lh-0"><i class="dis-ib h-6 w-30 bg-main"></i></p>
                <p class="fs-12 c-6 txt-r mt-10" v-html="$g('aboutsModel101.content')"></p>
                <div class="dis-flex j-c-between mt-20">
                    <img width="637" height="365" :src="$g('aboutsModel101.image1')" alt="">
                    <ul class="txt-c w-500 mt-20 dis-flex flex-column aboutus-model10-list">
                        <li :class="['flex-1 dis-flex flex-column j-c-center bg-f5f5f5',index==0 ?'':'bt-f']" v-for="item,index in $t.aboutsModel102 || []" :key="index">
                            <h3 class="fs-16 fw-b">{{item.title}}</h3>
                            <p class="c-6 mt-5">{{item.content}}</p>
                        </li>
                    </ul>
                </div>
                <div class="h-400 c-main fs-18 fw-b txt-c lh-30 pt-180" v-html="$g('aboutsModel101.ext1')"></div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "Aboutus",
        data() {
            return {
            }
        },
        mounted(){
            if( this.$route.query.id == 'friends'){
                document.getElementById('friends').scrollIntoView();
            }
        }
    }
</script>
<style>
    .aboutus-model1 {
        background: url(/static/images/aboutus/model1-bg.jpg) no-repeat center bottom;
        background-size: cover;
    }
    .aboutus-model3 {
        height: 643px;
        background: url(/static/images/aboutus/model3-bg.jpg) no-repeat center #f7f7f7;
        background-size: cover;
    }
    .aboutus-model3-list{z-index: 1;}
    .aboutus-model3-list::after{
        content: "";
        position: absolute;
        left: -53px;
        top: 55px;
        height: 4px;
        right: 0;
        transform: rotate(180deg);
        z-index: -1;
        background: linear-gradient(89deg, #B02125, #C79090);
    }
    .aboutus-model3-list .around{
        width: 22px;
        height: 22px;
        border: 1px solid #B02125;
        border-radius: 50%;
        background: #fff;
    }
    .aboutus-model3-list .around::after{
        content: "";
        position: absolute;
        left: 5px;
        top: 5px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #B02125;
    }
    .aboutus-model4 {
        height: 231px;
        padding: 68px 134px 0;
        background: url(/static/images/aboutus/model4-bg.jpg) no-repeat center;
        background-size: cover;
    }
    .aboutus-model5-title {
        position: absolute;
        left: 62px;
        bottom: 150px;
        z-index: -1;
        font-size: 171px;
        color: rgba(193, 59, 59, 0.07);
    }
    .aboutus-model5-bottom {
        position: absolute;
        left: 0;
        right: 331px;
        z-index: -1;
        bottom: 30px;
    }

    .aboutus-model6-title {
        position: absolute;
        right: 30px;
        bottom: 250px;
        z-index: -1;
        font-size: 171px;
        color: rgba(193, 59, 59, 0.07);
    }
    .aboutus-model6-bottom {
        position: absolute;
        right: 0;
        left: 447px;
        z-index: -1;
        bottom: 100px;
    }
    .aboutus-model7{
        height: 679px;
        background: url(/static/images/aboutus/model7-bg.jpg) no-repeat center top;
        background-size: 100%  564px;
    }
    .aboutus-model7-title::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -10px;
        width: 35px;
        height: 7px;
        background: #B02125;
    }
    .aboutus-model7-list {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 390px;
        background: #AC0000;
        opacity: 0.88;
    }
    .aboutus-model10{
        background: url(/static/images/aboutus/model10-bg.png) no-repeat center bottom;
        background-size: 100% 557px;
    }
    .aboutus-model10-list {
        margin-left: -200px;
        height: 289px;
        box-shadow: 0px 0px 38px 0px rgba(173, 45, 45, 0.15);
    }
    .aboutus-model10-list li:hover{
        background: #ad2d2d;
        color: #fff;
    }
    .aboutus-model10-list li:hover p{
        color: #fff;
    }
</style>
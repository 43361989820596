<template>
  <div>
    <div class="dis-flex a-i-center j-c-center h-527">
      <img
        class="h-p100 w-p100 objext-c"
        src="/static/images/joinus/detail-bg.jpg"
        alt=""
      />
    </div>
    <div class="w-1040 mlr-auto">
      <div
        class="h-90 dis-flex por br-10 j-c-between plr-40 a-i-center c-f bg-main fs-18 detail-model2-top"
      >
        <span>{{ $g("joinUsDetail.title") }}：{{ detailData.title }}</span>
        <span>{{ $g("joinUsDetail.content") }}：{{ detailData.content }}</span>
        <span>{{ $g("joinUsDetail.ext1") }}：{{ detailData.ext1 }}</span>
      </div>
      <div class="pd-40">
        <h3 class="fs-16 c-3 fw-b pb-15 bb-main-3">
          {{ $g("joinUsDetail.ext2") }} ( {{ detailData.ext4 }} ) :{{
            detailData.title
          }}
        </h3>
        <div class="c-6 lh-28 ck-content" v-html="detailData.bigtext"></div>
      </div>
    </div>
    <div class="bg-f8f8f8">
      <div class="w-1040 mlr-auto ptb-30">
        <h3 class="fs-16 c-main fw-b">{{ $g("joinUsDetail.ext3") }}</h3>
        <ul class="mt-20 dis-flex j-c-between">
          <li
            class="w-500 bg-f pd-30 cp detail-model3-item"
            v-for="(item, index) in moreList"
            :key="index"
          >
            <router-link :to="{ path: '/joinus/detail', query: { index: curIndex + index + 1 } }">
              <h4 class="dis-flex j-c-between">
                <span class="fs-16 fw-b">{{ item.title }}</span>
                <span class="fs-16 c-main fw-b">{{ item.content }}</span>
              </h4>
              <p class="fs-16 c-6 fw-b mt-25">{{ item.ext1 }}</p>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "JoinusDetail",
  data() {
    return {
        curIndex: 0,
    };
  },
  computed: {
    detailData() {
      let me = this;
      let arr = me.$t.joinUsModel4Data || [];
      let index = me.$route.query ? me.$route.query.index : 0;
      return arr[index] || {};
    },
    moreList() {
      let me = this;
      if (!me.$t.joinUsModel4Data) {
        return [];
      }
      let index = me.$route.query ? me.$route.query.index : "0";
      me.curIndex = parseInt(index);
      if(me.$t.joinUsModel4Data.length - 1 == me.curIndex ){
          me.curIndex = -1;
      }
      let end = me.curIndex + 3;
      return me.$t.joinUsModel4Data.slice(me.curIndex+1, end) || [];
    },
  },
};
</script>
<style lang="scss">
.detail-model2-top {
  margin-top: -45px;
  z-index: 1;
}
.detail-model3-item {
  border: 2px dashed #d4d4d4;
  &:hover {
    border: 2px solid #b8250f;
  }
}
</style>

<template>
  <div class="dis-flex j-c-center">
    <div class="w-1040 mlr-auto">
      <div class="pt-40 pb-25 c-9">
        <span
          v-for="(item, index) in nav"
          :key="index"
          class="cp"
          @click="navClick(item)"
        >{{ index == 0 ? "" : ">" }}{{ item.name }}</span>
      </div>
      <h3 class="fs-18 pb-20 bb-dcdcdc">{{ detailData.title }}</h3>
      <p class="h-70 dis-flex a-i-center fs-12 c-9 bb-dcdcdc">
        <span>{{ detailData.ext1 }}</span>
        <span class="ml-70">{{ detailData.author }}</span>
      </p>
      <div class="mt-20 c-6 ck-content" v-html="detailData.content"></div>
      <div class="mt-80">
        <div class="dis-flex c-3 j-c-between a-i-center pb-20 bb-dcdcdc">
          <h3 class="fs-18">{{ $g("newDetailNav.title") }}</h3>
          <span class="fs-12 cp" @click="moreClick">
            {{ $g("newDetailNav.content")
            }}
            <img
              class="ml-5"
              src="/static/images/new/detail-right.png"
              alt
            />
          </span>
        </div>
        <ul class="dis-flex mt-30 pb-60 j-c-between">
          <li
            v-for="(item, index) in moreList"
            :key="index"
            :class="[
              'w-p33',
              index == 0 ? 'pr-40 br-eaeaea' : index == 1 ? 'plr-40 ' : 'pl-40',
            ]"
          >
            <p>{{ item.ext1 }}</p>
            <router-link :to="{ path: '/new/detail', query: { id: item.id } }">
              <h3 :class="['text-td mt-10', index == 0 ? 'c-main' : '']">{{ item.title }}</h3>
            </router-link>
            <p class="text-td-3 mt-15">{{ item.memo }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "NewDetail",
  data() {
    let me = this;
    return {
      id: null,
      detailData: {
        title: "",
        time: "",
        author: "",
        content: "",
      },
    };
  },
  computed: {
    nav() {
      let me = this;
      return [
        { name: me.$g("newDetailNav.ext1"), url: "/home" },
        { name: me.$g("newDetailNav.ext2"), url: "/new" },
      ];
    },
    moreList() {
      let me = this;
      if (!me.$a.newArticle) {
        return [];
      }
      let liet = [];
      for (let i = me.$a.newArticle.length - 1; i > 0; --i) {
        if (liet.length > 2) {
          break;
        }
        let art = me.$a.newArticle[i];
        if (art.id == me.id) {
          continue;
        }
        liet.push(art);
      }
      return liet.reverse() || [];
    },
  },
  methods: {
    navClick(item) {
      this.$router.push({
        path: item.url,
      });
    },
    moreClick() {
      let me = this;
      me.$router.push("/new");
    },
    async articleDetail() {
      let me = this;
      me.id = me.$route.query.id;
      try {
        // let d =
        //   (
        //     await axios.get("/official-page-web/data/mh-article/detail", {
        //       params: {
        //         id: me.id,
        //       },
        //     })
        //   ).data.data || {};
        let d = await me.$app.inter.getMhArticalDetail({
          id: me.id,
          prjCode: "ymoffical" + me.$app.curLan.code,
        });
        me.detailData = d;
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    let me = this;
    me.articleDetail();
  },
  watch: {
    $route(to, from) {
      let me = this;
      if (to.path == "/new/detail") {
        if (me.id != me.$route.query.id) {
          me.articleDetail();
        }
      }
    },
  },
};
</script>

<style>
</style>
<template>
  <div>
    <!-- 模块1 - 大图 -->
    <div class="dis-flex a-i-center j-c-center h-527" :style="newModel1">
      <h3 class="c-fefefe fs-48 fw-b">{{ $g("newBG.title") }}</h3>
    </div>
    <!-- 模块2 - 数据列表 -->
    <ul class="mlr-auto w-1040">
      <li
        v-for="(item, index) in articleList || []"
        :key="index"
        class="mt-70 dis-flex"
      >
        <img width="300" height="170" :src="item.image1" alt="" />
        <div
          class="h-170 lh-18 ml-40 pb-10 flex-1 dis-flex flex-column j-c-between new-model2-listright"
        >
          <h3 class="fs-16 c-3 fw-b">{{ item.title }}</h3>
          <p class="fs-14 c-6 mt-20 text-td-3">{{ item.memo }}</p>
          <div class="dis-flex j-c-between a-i-center mt-20">
            <span class="fs-12 c-9">{{ item.ext1 }}</span>
            <span
              class="dis-ib c-b41122 fs-12 pb-4 cp bb-main"
              @click="toDetail(item)"
              >{{ $g("newOther.title") }}</span
            >
          </div>
        </div>
      </li>
    </ul>
    <!-- 模块3 - 分页 -->
    <ul class="mlr-auto w-1040 dis-flex a-i-center j-c-r ptb-45 fs-12">
      <li
        :class="[
          'bg-main c-f plr-15 h-20 lh-20 cp mr-15',
          page == 1 ? 'disable' : '',
        ]"
        @click="prevClick"
      >
        {{$g("newOther.ext1")}}
      </li>
      <template v-for="(item, index) in pageTotal - 1">
        <li
          v-if="item < size || page == item || page > item"
          :key="index"
          :class="[
            'w-20 cp h-20 dis-flex a-i-center j-c-center ml-5',
            page == item ? 'c-main bd-main' : 'bd-b1b1b1 c-b1b1b1',
          ]"
          @click="pageClick(item)"
        >
          <span>{{ item }}</span>
        </li>
      </template>
      <li
        v-if="pageTotal > 5 && page != pageTotal - 1 && page != pageTotal"
        class="w-20 h-20 dis-flex a-i-center j-c-center ml-5 bd-b1b1b1 c-b1b1b1"
      >
        ...
      </li>
      <li
        :class="[
          'w-20 h-20 cp dis-flex a-i-center j-c-center ml-5',
          page == pageTotal ? 'c-main bd-main' : 'bd-b1b1b1 c-b1b1b1',
        ]"
        @click="pageClick(pageTotal)"
      >
        {{ pageTotal }}
      </li>
      <li
        :class="[
          'bg-main c-f plr-15 h-20 lh-20 cp ml-30',
          page == pageTotal ? 'disable' : '',
        ]"
        @click="nextClick"
      >
        {{$g("newOther.ext2")}}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "New",
  data() {
    let me = this;
    return {
      page: 1,
      size: 4
    };
  },
  methods: {
    prevClick() {
      if (this.page == 1) return;
      this.page -= 1;
    },
    pageClick(i) {
      this.page = i;
    },
    nextClick() {
      if (this.page == this.pageTotal) return;
      this.page += 1;
    },
    toDetail(item) {
      this.$router.push({ name: "NewDetail", query: { id: item.id } });
    },
  },
  computed: {
    pageTotal() {
      let me = this;
      let total = me.$a.newArticle ? me.$a.newArticle.length : 1;
      return Math.ceil(total / me.size);
    },
    articleList() {
      let me = this;
      let index = me.page - 1;
      let start = index * me.size;
      let end = index * me.size + me.size;
      return me.$a.newArticle ? me.$a.newArticle.slice(start, end) : [];
    },
    newModel1() {
      let me = this;
      return `
       background-image: url(${me.$t.newBG ? me.$t.newBG.image1 : ""});
       background-repeat: no-repeat;
       background-attachment:center;
       background-size:cover;`;
    },
  },
};
</script>
<style>
.new-model2-listright {
  border-bottom: 3px solid #f7f7f7;
}
.disable {
  background: #b02125;
  opacity: 0.36;
}
</style>

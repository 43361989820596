<template>
    <div>
        <!-- 模块1 - 大图 -->
        <div class="dis-flex a-i-center j-c-center h-527 joinus-model1">
            <h3 class="c-fefefe fs-48 fw-b">{{$g('joinUsModel1.title')}}</h3>
        </div>
        <!-- 模块2 -->
        <div class="pt-70 pb-150 w-1040 bg-f3f3f3 mlr-auto joinus-model2">
            <h3 class="c-3 fs-18">{{$g('joinUsModel2.title')}}</h3>
            <p><i class="dis-ib h-6 w-35 bg-main mt-10"></i></p>
            <ul class="mt-60 dis-flex">
                <li class="mr-20">
                    <h1 class="fs-72 c-main fw-b">{{$g('joinUsModel2.ext1')}}</h1>
                    <p class="fs-12 c-6">{{$g('joinUsModel2.ext2')}}</p>
                    <p class="bg-main plr-10 dis-flex a-i-center j-c-center h-15 w-30 mt-25"><img  width="19" height="9" src="/static/images/main/icon-more.png" alt=""></p>
                </li>
                <li class="flex-1 bg-main dis-flex">
                    <div class="lh-0"><img class="object-c" width="322" height="100%" :src="$g('joinUsModel2.image1')" alt=""></div>
                    <div class="c-f pd-20 flex-1 dis-flex a-i-center">
                        <div class="ck-content" v-html="$g('joinUsModel2.bigtext')"></div>
                    </div>
                </li>
            </ul>
        </div>
        <!-- 模块3 -->
        <div class="joinus-model3">
            <div class="w-1040 mlr-auto">
                <h3 class="c-f fs-20">{{$g('joinUsModel3.title')}}</h3>
                <p><i class="dis-ib h-6 w-35 bg-main mt-10"></i></p>
                <div class="mt-25 fs-14 c-f lh-18 ck-content" v-html="$g('joinUsModel3.bigtext')"></div>
            </div>
        </div>
        <!-- 模块4 -->
        <div class="w-1040 joinus-model4" v-if="$g('joinUsModel3.ext1')!='nonext'">
            <ul class="dis-flex bg-main br-10 fw-b c-f h-95">
                <li :class="['h-p100 dis-flex a-i-center',index==0?'w-p33 plr-50':'flex-1 j-c-center']" v-for="item,index in $t.joinUsModel4Head" :key="index">{{item.title}}</li>
            </ul>
            <ul class="por br-10 h-80 mt-20 bg-fcfcfc dis-flex flex-wrap c-6 joinus-model4-table" v-for="item,index in $t.joinUsModel4Data||[]" :key="index" @click="showDetail(item,index)">
                <li class="dis-flex a-i-center w-p33 plr-50 text-td c-3 text-td cp">{{item.title}}</li>
                <li class="dis-flex a-i-center flex-1 j-c-center txt-c cp">{{item.content}}</li>
                <li class="dis-flex a-i-center flex-1 j-c-center txt-c cp">{{item.ext1}}</li>
                <li class="dis-flex a-i-center flex-1 j-c-center txt-c  cp c-0090ff">{{item.ext2}}</li>
            </ul>
            <p class="fs-12 c-main pt-10 pb-30">{{$g("joinMore.title")}}</p>
        </div>
    </div>
</template>
<script>
    export default {
        name: "Joinus",
        data() {
            return {
               
            }
        },
        methods: {
            showDetail(item,index){
                this.$router.push({ path: '/joinus/detail',query:{index:index}})
            }   
        }
    }
</script>
<style>
    .joinus-model1 {
        background: url(/static/images/joinus/model1-bg.jpg) no-repeat center;
        background-size: cover;
    }
    .joinus-model2 {
        background-color: #f3f3f3;
        background: url(/static/images/joinus/model2-bg1.png) no-repeat left bottom,
                    url(/static/images/joinus/model2-bg2.png) no-repeat right bottom;
    }
    .joinus-model3 {
        padding: 115px 140px 146px 140px;
        background: url(/static/images/joinus/model3-bg.jpg) no-repeat center;
        background-size: cover;
    }
    .joinus-model4 {
        margin: -60px auto 0;
    }
    .joinus-model4-item:hover {
        background: #b02125;
        color: #fff;
    }
    .joinus-model4-item:not(:first-child)::before{
        content: "";
        position: absolute;
        left: -1px;
        top: 10px;
        bottom: 0;
        border-right: 1px solid #f4f4f4;
    }
    .joinus-model4-table{
        box-shadow: 0px 0px 5px 0px rgba(29, 29, 29, 0.05);
    }
</style>
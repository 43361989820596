<template>
  <div>
    <!-- 模块1 - 大图 -->
    <div
      class="dis-flex a-i-center j-c-center h-527 business-model1"
      :style="businessModel1"
    >
      <h3 class="c-fefefe fs-48 fw-b">{{ $g("businessBG.title") }}</h3>
    </div>
    <!-- 模块2 - 业务内容列表 -->
    <div class="pt-80">
      <div class="w-1200 mlr-auto">
        <h3 class="txt-c c-3 fs-18 fw-b pb-10">
          <span class="por pb-10 business-model2-title">{{
            $g("businessListTitle.title")
          }}</span>
        </h3>
        <p class="txt-c c-878787 mt-25 mb-50">
          {{ $g("businessListTitle.content") }}
        </p>
        <ul class="pb-10 dis-flex flex-wrap j-c-center business-model2-list">
          <li
            v-for="(item, index) in $t.businessListContent || []"
            :key="index"
            class="plr-15 mb-60 cp w-350"
            @click="toDetail(index)"
          >
            <div class="br-10 por lh-0 bg-edf1f5 h-240 box">
              <img class="br-tr-10 img" :src="item.image1" />
              <p
                class="h-65 lh-18 plr-50 dis-flex a-i-center j-c-center txt-c c-6 fs-12 content"
              >
                <span class="text-td-2">{{ item.content }}</span>
              </p>
            </div>
            <h3 class="mt-20 txt-c">
              <span class="pb-5 c-3 fs-16 title">{{ item.title }}</span>
            </h3>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Business",
  data() {
    return {};
  },
  methods: {
    toDetail(index) {
      this.$router.push({
        path: "/business/detail",
        query: { index: index },
      });
    },
  },
  computed: {
    businessModel1() {
      let me = this;
      return `
       background-image: url(${me.$g("businessBG.image1")});
       background-repeat: no-repeat;
       background-attachment:center;
       background-size:cover;`;
    },
  },
};
</script>
<style>
.business-model1 {
  /* background: url(/static/images/business/model1-bg.jpg) no-repeat center;
  background-size: cover; */
}
.business-model2-title::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 34px;
  height: 6px;
  background: #b41122;
}

.business-model2-list .img {
  width: 100%;
  height: 175px;
  object-fit: cover;
}
.business-model2-list li:hover .img {
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.business-model2-list li:hover .content {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  height: 100%;
  color: #fff;
  padding: 22px;
}
.business-model2-list li:hover .title {
  color: #b41122;
  border: none;
}
</style>